import { Route } from 'react-router-dom';
import ErrorBoundary from '../components/ErrorComponent/ErrorBoundary';
import { SendIncidencesServiceManagerProvider } from '../Services/Incidences/SendIncidencesServices';
import { OrganizationChartServiceManagerProvider } from '../Services/OrganizationChart/OrganizationCharService';
import { lazy, Suspense } from 'react';
import Spinner from '../components/Spiner';
import { InvitationsServiceManagerProvider } from '../Services/Invitations/InvitationsService';

const AssistScreen = lazy(() =>
	import('../screens/Assist').then((module) => ({
		default: module.AssistScreen,
	}))
);
const CollaboratorScreen = lazy(() =>
	import('../screens/Collaborators').then((module) => ({
		default: module.CollaboratorScreen,
	}))
);
const HomeScreen = lazy(() =>
	import('../screens/Home').then((module) => ({ default: module.HomeScreen }))
);
const InvitationsScreen = lazy(() =>
	import('../screens/Invitations').then((module) => ({
		default: module.InvitationsScreen,
	}))
);
const Logout = lazy(() =>
	import('../screens/Logout').then((module) => ({
		default: module.Logout,
	}))
);
const NotFound = lazy(() =>
	import('../components/NotFound').then((module) => ({
		default: module.NotFound,
	}))
);
const ConfigurationScreen = lazy(() =>
	import('../screens/Configuration').then((module) => ({
		default: module.ConfigurationScreen,
	}))
);
const Catalogs = lazy(() =>
	import('../screens/Catalogs').then((module) => ({
		default: module.Catalogs,
	}))
);
const Operations = lazy(() =>
	import('../screens/Operation/Index').then((module) => ({
		default: module.Operations,
	}))
);
const IncidencesScreen = lazy(() =>
	import('../screens/Incidenses').then((module) => ({
		default: module.IncidencesScreen,
	}))
);
const QueriesComponent = lazy(() =>
	import('../screens/Queries').then((module) => ({
		default: module.QueriesComponent,
	}))
);
const OrganizationChart = lazy(() =>
	import('../screens/OrganizationChart').then((module) => ({
		default: module.OrganizationChart,
	}))
);
const VacationScreen = lazy(() =>
	import('../screens/Vacations').then((module) => ({
		default: module.VacationScreen,
	}))
);

const routerBrowser = [
	{
		path: '/',
		name: 'Home',
		element: <HomeScreen />,
	},
	{
		path: '/applications',
		name: 'Applications',
		element: (
			<ErrorBoundary>
				<CollaboratorScreen />
			</ErrorBoundary>
		),
	},
	{
		path: '/vacations',
		name: 'Vacations',
		element: (
			<ErrorBoundary>
				<VacationScreen />
			</ErrorBoundary>
		),
	},
	{
		path: '/assist',
		name: 'Assist',
		element: (
			<ErrorBoundary>
				<AssistScreen />
			</ErrorBoundary>
		),
	},
	{
		path: '/invitations',
		name: 'Invitations',
		element: (
			<ErrorBoundary>
				<InvitationsServiceManagerProvider>
					<InvitationsScreen />
				</InvitationsServiceManagerProvider>
			</ErrorBoundary>
		),
	},
	{
		path: '/centralModuleLogout',
		name: 'logout',
		element: <Logout />,
	},
	{
		path: '/catalogs',
		name: 'catalogs',
		element: (
			<ErrorBoundary>
				<Catalogs />
			</ErrorBoundary>
		),
	},
	{
		path: '/configuration',
		name: 'configuration',
		element: (
			<ErrorBoundary>
				<ConfigurationScreen />
			</ErrorBoundary>
		),
	},
	{
		path: '/operation',
		name: 'operation',
		element: (
			<ErrorBoundary>
				<Operations />
			</ErrorBoundary>
		),
	},
	{
		path: '/incidences',
		name: 'incidences',
		element: (
			<ErrorBoundary>
				<SendIncidencesServiceManagerProvider>
					<IncidencesScreen />
				</SendIncidencesServiceManagerProvider>
			</ErrorBoundary>
		),
	},
	{
		path: '/queries',
		name: 'queries',
		element: (
			<ErrorBoundary>
				<QueriesComponent />
			</ErrorBoundary>
		),
	},
	{
		path: '/organizationChart',
		name: 'organizationChart',
		element: (
			<ErrorBoundary>
				<OrganizationChartServiceManagerProvider>
					<OrganizationChart />
				</OrganizationChartServiceManagerProvider>
			</ErrorBoundary>
		),
	},
	{
		path: '*',
		name: 'NotFound',
		element: <NotFound />,
	},
];
export default routerBrowser.map((route) => (
	<Route
		key={route.name}
		path={route.path}
		element={<Suspense fallback={<Spinner />}>{route.element}</Suspense>}
	/>
));
