import { useContext, useEffect } from 'react';
import { StoreContext } from '../../business/Provider';
import useApi from '../api';
import { generateUrlBase } from '../../utils/utils';
import {
	GET_PERIODS,
	GET_PERIODS_ERROR,
	GET_PERIODS_SUCCESS,
	LOADING_OFF,
	LOADING_ON,
	PUT_IMPORT_PERIODS,
	PUT_IMPORT_PERIODS_ERROR,
	PUT_IMPORT_PERIODS_SUCCESS,
} from '../../business/constants';
import { Toast } from '../../components/toast';

export const usePeriods = () => {
	const context = useContext(StoreContext);
	const {
		getPeriodState,
		dispatchGetPeriod,
		dispatchLoading,
		dispatchPutImportPeriods,
	} = context;
	const { genericApiCall } = useApi();

	const getPeriods = async () => {
		dispatchGetPeriod({ type: GET_PERIODS });
		dispatchLoading({ type: LOADING_ON });
		const baseUrl = generateUrlBase('periods/types');
		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const payload = result.data.results;

			if (result.status === 200) {
				dispatchGetPeriod({
					type: GET_PERIODS_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchGetPeriod({
					type: GET_PERIODS_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchGetPeriod({ type: GET_PERIODS_ERROR });
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	const importPeriods = async (periodTypeId) => {
		dispatchPutImportPeriods({ type: PUT_IMPORT_PERIODS });
		dispatchLoading({ type: LOADING_ON });
		const baseUrl = generateUrlBase('periods/import');
		const param = {
			periodTypeId: periodTypeId,
		};
		try {
			const result = await genericApiCall(baseUrl, 'PUT', param, {}, {});
			const payload = result.data.results;

			if (result.status === 200) {
				dispatchPutImportPeriods({
					type: PUT_IMPORT_PERIODS_SUCCESS,
					payload: payload,
				});

				dispatchGetPeriod({
					type: GET_PERIODS_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
				//return getPeriods();
			} else {
				let message = {
					body: '',
					header: 'Error',
				};
				if (result.message) message.body = result.message;
				dispatchPutImportPeriods({
					type: PUT_IMPORT_PERIODS_ERROR,
					payload: message,
				});
				dispatchLoading({ type: LOADING_OFF });
			}
			return result;
		} catch (error) {
			console.log('error xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx', error);
			//Toast('warning', 'No se encontraron periodos intente con otro tipo.');
			Toast('warning', error.message, '', error);
			dispatchPutImportPeriods({ type: PUT_IMPORT_PERIODS_ERROR });
			dispatchLoading({ type: LOADING_OFF });
		}
	};

	useEffect(() => {
		if (
			//Array.isArray(getInvitationsState?.invitations) &&
			getPeriodState?.periods === null &&
			getPeriodState?.loading === false &&
			getPeriodState.error === null
		) {
			getPeriods();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getPeriodState]);
	return { getPeriods, importPeriods };
};
