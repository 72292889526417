import { HiOutlineMail } from 'react-icons/hi';
import {
	BsCalendarDay,
	BsCardChecklist,
	BsPersonFill,
	BsPersonWorkspace,
} from 'react-icons/bs';
import { CiMoneyCheck1 } from 'react-icons/ci';
import {
	MdOutlineAlarm,
	MdOutlineDataset,
	MdOutlineEventSeat,
	MdOutlineHomeWork,
	MdOutlineToday,
	MdOutlineViewTimeline,
	MdOutlineSettingsInputComponent,
	MdOutlineSyncProblem,
} from 'react-icons/md';
import { AiOutlineSchedule } from 'react-icons/ai';
import { RiListCheck2 } from 'react-icons/ri';
import { IoSettingsOutline } from 'react-icons/io5';
import { SiDatabricks } from 'react-icons/si';
import { FaUserCog } from 'react-icons/fa';
import { GiOrganigram } from 'react-icons/gi';

export const IconService = (icon, className) => {
	switch (icon) {
		case 'GiOrganigram':
			return <GiOrganigram className={className} />;
		case 'HiOutlineMail':
			return <HiOutlineMail className={className} />;
		case 'BsPersonFill':
			return <BsPersonFill className={className} />;
		case 'BsCardChecklist':
			return <BsCardChecklist className={className} />;
		case 'BsCalendarDay':
			return <BsCalendarDay className={className} />;
		case 'CiMoneyCheck1':
			return <CiMoneyCheck1 className={className} />;
		case 'MdOutlineEventSeat':
			return <MdOutlineEventSeat className={className} />;
		case 'AiOutlineSchedule':
			return <AiOutlineSchedule className={className} />;
		case 'BsPersonWorkspace':
			return <BsPersonWorkspace className={className} />;
		case 'RiListCheck2':
			return <RiListCheck2 className={className} />;
		case 'IoSettingsOutline':
			return <IoSettingsOutline className={className} />;
		case 'SiDatabricks':
			return <SiDatabricks className={className} />;
		case 'FaUserCog':
			return <FaUserCog className={className} />;
		case 'MdOutlineHomeWork':
			return <MdOutlineHomeWork className={className} />;
		case 'MdOutlineToday':
			return <MdOutlineToday className={className} />;
		case 'MdOutlineAlarm':
			return <MdOutlineAlarm className={className} />;
		case 'MdOutlineViewTimeline':
			return <MdOutlineViewTimeline className={className} />;
		case 'MdOutlineDataset':
			return <MdOutlineDataset className={className} />;
		case 'MdOutlineSettingsInputComponent':
			return <MdOutlineSettingsInputComponent className={className} />;
		case 'MdOutlineSyncProblem':
			return <MdOutlineSyncProblem className={className} />;

		default:
			return <HiOutlineMail className={className} />;
	}
};
