import React, { useState, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { BreadCrumb } from '../components/BreadCrumb';
import { NavBar } from '../components/NavBar';
import { Sidebar } from '../components/Sidebar';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useMsal } from '@azure/msal-react';
import { Button } from 'primereact/button';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog } from 'primereact/dialog';
import personiaLogo from '../assets/img/Logo_CONTPAQi_Colabora.png';
import useCompanySetting from '../hooks/company/useCompanySetting';
export const MainLayout = () => {
	const [showPopover, setShowPopover] = useState(false);
	const [showCloseDialog, setShowCloseDialog] = useState(false);
	const [isExpand, setIsExpand] = useState(false);
	const refCloseProfileCard = useRef(null);
	const [showDialog, setShowDialog] = useState(false);

	const { getCompanySettings } = useCompanySetting();

	useEffect(() => {
		const data = async () => {
			return await getCompanySettings();
		};
		data();
	}, []);

	useEffect(() => {
		if (showPopover) {
			const checkIfClickedOutside = (e) => {
				if (
					refCloseProfileCard.current &&
					!refCloseProfileCard.current.contains(e.target)
				) {
					setShowPopover(false);
				}
			};
			window.addEventListener('mousedown', checkIfClickedOutside);
			return () => {
				window.removeEventListener('mousedown', checkIfClickedOutside);
			};
		}
	}, [showPopover]);

	const { instance } = useMsal();

	const [wUl, setWUl] = useState(' w-[90px] ');

	const signOff = async () => {
		const currentAccount = instance.getAllAccounts()[0];
		instance.logoutRedirect({
			account: currentAccount,
		});
		sessionStorage.removeItem('sessionState');
		window.localStorage.clear();

		return null;
	};

	const renderFooterDialog = () => {
		return (
			<div>
				<Button
					label='SÍ, DESEO SALIR'
					icon='pi pi-check'
					onClick={() => {
						signOff();
						setShowCloseDialog(false);
					}}
					autoFocus
				/>
				<Button
					label='NO, PERMANECER AQUÍ'
					icon='pi pi-times'
					onClick={() => setShowCloseDialog(false)}
					className='p-button-text'
				/>
			</div>
		);
	};

	const closeDialog = () => {
		return (
			<ConfirmDialog
				footer={renderFooterDialog()}
				visible={showCloseDialog}
				onHide={() => setShowCloseDialog(false)}
				message='¿Estás seguro de que quieres terminar la sesión?'
				header='FINALIZAR SESIÓN'
				// acceptLabel='SÍ, DESEO SALIR'
				// rejectLabel='NO, PERMANECER AQUÍ'
				accept={() => signOff()}
				reject={() => setShowCloseDialog(false)}
			/>
		);
	};

	const renderFooter = () => {
		return (
			<div>
				<Button
					label='Ok'
					onClick={() => setShowDialog(false)}
					className='p-button-text'
				/>
			</div>
		);
	};

	const renderDialog = () => {
		return (
			<Dialog
				header='Acerca de'
				visible={showDialog}
				style={{ width: '30vw' }}
				footer={renderFooter()}
				onHide={() => setShowDialog(false)}>
				<div className='flex w-full h-full flex-col justify-center items-center content-center'>
					<div className='flex w-[125px] pl-1 justify-center item-center align-bottom m-auto'>
						<img
							className='object-scale-down w-full h-12'
							src={personiaLogo}
							alt='Logo'
						/>
					</div>
					<p>{'Liberación: ' + window.REACT_APP_RELEASE_VERSION}</p>
					<p>{'Compilación: ' + window.REACT_APP_BUILD_VERSION}</p>
				</div>
			</Dialog>
		);
	};

	return (
		<div className='flex h-screen w-full flex-col overflow-hidden'>
			<NavBar
				showPopover={showPopover}
				setShowPopover={setShowPopover}
				refCloseProfileCard={refCloseProfileCard}
				closeFunction={setShowCloseDialog}
				setShowDialog={setShowDialog}
			/>
			<div className='flex w-full flex-grow flex-row relative'>
				<div className={'flex h-full relative  ' + wUl}>
					<Sidebar
						onClick={() => setIsExpand(!isExpand)}
						isExpand={isExpand}
						setWUl={setWUl}
					/>
				</div>

				{
					<div className='flex grow  flex-col relative'>
						<div className='flex h-[44px] w-full bg-white flex-row  drop-shadow-md'>
							<BreadCrumb />
						</div>
						<div className='flex w-full h-full '>
							<Outlet />
							{renderDialog()}
							{showCloseDialog && closeDialog()}
							<ToastContainer
								position='bottom-right'
								autoClose={3000}
								hideProgressBar={false}
								newestOnTop={false}
								closeOnClick
								rtl={false}
								pauseOnFocusLoss
								draggable
								pauseOnHover
								theme='light'
							/>
						</div>
					</div>
				}
			</div>
		</div>
	);
};
