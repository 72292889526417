import { useContext } from 'react';
import { StoreContext } from '../../business/Provider';
import useApi from '../api';
import { generateUrlBase } from '../../utils/utils';
import {
	GET_COMPANY_VERIFY_LICENSE,
	GET_COMPANY_VERIFY_LICENSE_ERROR,
	GET_COMPANY_VERIFY_LICENSE_SUCCESS,
	LOADING_OFF,
	LOADING_ON,
} from '../../business/constants';
import { Toast } from '../../components/toast';
import { msalConfig } from '../../Auth/b2cService/b2cServiceConfig';

const useVerifyLicense = () => {
	const context = useContext(StoreContext);

	const { dispatchCompanyVerifyLicense, dispatchLoading } = context;
	const onChangeCompany = () => {
		// Obtiene la URL del portal desde el almacenamiento local o desde la configuración del usuario si no existe
		const redirect = localStorage.getItem('ctiPortalURI');
		console.log('redirect', redirect);

		let url;

		if (redirect !== null) {
			// Elimina todas las comillas dobles de la URL
			url = redirect.replace(/"/g, '');
		} else {
			// Usa la URL de configuración si no hay valor en el almacenamiento local
			url = msalConfig.ctiPortalURI;
		}

		// Abre la URL en una nueva pestaña
		return window.location.replace(url); //window.open(url);
	};

	const { genericApiCall } = useApi();
	const verifyLicense = async () => {
		const baseUrl = generateUrlBase('company/verify-license');
		dispatchCompanyVerifyLicense({ type: GET_COMPANY_VERIFY_LICENSE });
		dispatchLoading({ type: LOADING_ON });

		try {
			const result = await genericApiCall(baseUrl, 'GET', {}, {}, {});
			const payload = result.data;
			if (result.status === 200) {
				dispatchCompanyVerifyLicense({
					type: GET_COMPANY_VERIFY_LICENSE_SUCCESS,
					payload: payload,
				});
				dispatchLoading({ type: LOADING_OFF });
			} else if (result.status === 401) {
				Toast(
					'warning',
					'La empresa a la cuál desea entrar no está permitida en los accesos de su licencia'
				);
				return onChangeCompany();
			}
			return result;
		} catch (error) {
			Toast('warning', error.message, '', error);
			dispatchLoading({ type: LOADING_OFF });
			dispatchCompanyVerifyLicense({ type: GET_COMPANY_VERIFY_LICENSE_ERROR });
			if (error.status === 401) {
				Toast(
					'warning',
					'La empresa a la cuál desea entrar no está permitida en los accesos de su licencia'
				);
				return onChangeCompany();
			}
		}
	};
	return { verifyLicense };
};

export default useVerifyLicense;
