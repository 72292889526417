import React, { useContext, useEffect, useRef, useState } from 'react';

import { NavigationContext } from '../../context/navigationContext';

import { ButtonDropDown } from '../Buttons/ButtonDropdown';
import { SubSidebar } from '../SubSidebar';
import { IconComponent } from '../IconComponent';
export const SidebarItem = ({
	itemSidebar,
	isSelectValue,
	setIsSelect,
	setDataSecondSidebar,
	dataSecondSidebar,
	open,
	setOpen,
}) => {
	const navigation = useContext(NavigationContext);
	// const { t } = useCustomTranslation();

	const color =
		isSelectValue === itemSidebar?.url ? '!text-white' : ' !text-white';
	//set open false on de-focus
	const ref = useRef(null);

	const [deFocus, setDeFocus] = useState(false);

	useEffect(() => {
		const handleClick = (e) => {
			if (ref?.current && !ref.current?.contains(e.target)) {
				setDeFocus(true);
			}
		};
		document.addEventListener('mousedown', handleClick);
		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	useEffect(() => {
		if (deFocus) {
			if (open[itemSidebar?.id]) {
				setOpen({ ...open, [itemSidebar?.id]: false });
			}
			setDeFocus(false);
		}
	}, [deFocus]);

	const handlerClose = () => {
		setOpen({ ...open, [itemSidebar?.id]: false });
	};

	return (
		<li className='relative h-[90px] flex ' ref={ref}>
			<SubSidebar
				subSidebarData={dataSecondSidebar}
				current={open[itemSidebar?.id]}
				close={handlerClose}
				setIsSelect={setIsSelect}
				setDataSecondSidebar={setDataSecondSidebar}
			/>

			<ButtonDropDown
				id='dropdownDefault'
				data-dropdown-toggle='dropdown'
				keyItem={itemSidebar?.id}
				onClick={() => {
					if (itemSidebar?.childrends !== undefined) {
						setIsSelect(itemSidebar?.url);
						setDataSecondSidebar(itemSidebar);
						//setWUl(' w-[300px] ');
						// set item.id to true and others to false
						const newOpen = Object.assign({}, open);
						// all items to false
						Object.keys(newOpen).forEach((key) => {
							if (key !== itemSidebar?.id) {
								newOpen[key] = false;
							} else {
								newOpen[key] = !newOpen[key];
							}
						});
						setOpen(newOpen);
					} else {
						navigation.selectTap(itemSidebar.url, '', itemSidebar.title);
						setIsSelect(itemSidebar?.url);
						setDataSecondSidebar([]);
						//setWUl(' w-[90px] ');
					}
				}}
				className={`!justify-center flex-grow !font-h3-font-family !border-none hover:!bg-menu-bg-default ${
					open !== undefined &&
					itemSidebar.id !== undefined &&
					open[itemSidebar?.id]
						? '!bg-menu-bg-default'
						: '!bg-transparent'
				}`}>
				<div className={`flex h-full w-full  flex-col `}>
					<div className='flex w-full m-auto  items-center content-center justify-center flex-col '>
						{/* <FontAwesomeIcon
							className={`${color}`}
							size='2x'
							icon={item?.icon}
						/>
						<p className={`${color} text-xs`}>{item?.translate}</p> */}
						<IconComponent
							iconName={itemSidebar.icon}
							className={'text-5xl text-white'}
						/>
						<p className={`${color} text-xs`}>{itemSidebar.translate}</p>
					</div>
				</div>
			</ButtonDropDown>
		</li>
	);
};
