import { formatDate } from '../../../utils/utils';
export function getDateTime() {
	const now = new Date();
	const year = now.getFullYear();
	const month = String(now.getMonth() + 1).padStart(2, '0');
	const day = String(now.getDate()).padStart(2, '0');
	const hours = String(now.getHours()).padStart(2, '0');
	const minutes = String(now.getMinutes()).padStart(2, '0');
	const seconds = String(now.getSeconds()).padStart(2, '0');
	const format = `${year}${month}${day}_${hours}${minutes}${seconds}`;
	return format;
}
function convertDate(inputFormat) {
	function pad(s) {
		return s < 10 ? '0' + s : s;
	}
	let d = new Date(inputFormat);
	return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
}
function getDatesInRange(startDate, endDate) {
	const datesInRange = [];
	let currentDate = new Date(startDate);

	while (currentDate <= endDate) {
		let parsedDate = new Date(currentDate);
		datesInRange.push(convertDate(parsedDate));
		currentDate.setDate(currentDate.getDate() + 1);
	}

	return datesInRange;
}

// Function to add missing dates to the array
export function addMissingDates(array, startDate, endDate) {
	array.forEach((colaborator) => {
		colaborator.Code = parseInt(colaborator.Code);
		const datesInRange = getDatesInRange(
			new Date(startDate),
			new Date(endDate)
		);

		datesInRange.forEach((date) => {
			const existingDate = colaborator.hasOwnProperty(date);

			if (!existingDate) {
				colaborator[date] = null;
			}
		});
	});

	return array;
}
export function dataToExcel(
	dynamicColumns,
	companyInfo,
	typeQuerySelect,
	tableDataQuery
) {
	// modificar data en los campos requestDate y statusChangeDate para formato de fecha dd/mm/yyyy
	const toExcel = {
		filename: 'Colaboradores',
		templateFileName: 'query_template.xlsx',
		replace: [
			{
				type: 0,
				tag: '#simple.name',
				prop: 'name',
				default: '',
			},
			{
				type: 1,
				tag: '#simple.company',
				prop: 'company',
				default: '',
			},
			{
				type: 1,
				tag: '#printDate',
				prop: 'printDate',
				default: new Date().toLocaleDateString(),
				format: ['numberFormat', 'dd mmmm yyyy'],
			},
			{
				type: 1,
				tag: '#simple.appName',
				prop: 'appName',
				default: '',
			},
			{
				type: 1,
				tag: '#simple.name',
				prop: 'name',
				default: '',
			},
			{
				type: 2,
				tag: '#simple.cols',
				prop: 'cols',
			},
			{
				type: 4,
				tag: '#simple.rows',
				prop: 'rows',
				colprop: 'cols',
				default: 'N/D',
			},
		],
		data: {
			company: companyInfo?.companies?.name ? companyInfo.companies.name : '',
			autoFilter: true,
			gridLinesVisible: true,
			printDate: new Date().toLocaleDateString(),
			appName: 'CONTPAQi Colabora',
			name:
				typeQuerySelect?.code === 'I'
					? 'Consulta Incidencias'
					: 'Consulta Checadas',
			cols: [],
			rows:
				typeQuerySelect?.code === 'I'
					? dataIncidencesToExport(tableDataQuery)
					: dataCheckToExport(tableDataQuery),
		},
	};
	// cols = [{ title: "", prop:"", width: ""}]
	// title es el nombre de la columna y prop es el nombre de la propiedad del objeto
	// width es el ancho de la columna

	dynamicColumns.forEach((header, index) => {
		let width = parseInt(header.width);
		switch (header.field) {
			case 'code':
				width = 20;
				break;
			case 'name':
				width = 30;
				break;
			default:
				width = 25;
				break;
		}
		const col = {
			title: header.header,
			prop: header.field,
			width: width,
		};
		toExcel.data.cols.push(col);
	});
	return toExcel;
}
const dataCheckToExport = (tableDataQuery) => {
	const result = [];
	tableDataQuery.forEach((element) => {
		const obj = {};
		Object.keys(element).forEach((key) => {
			const isDate = /\d{2}\/\d{2}\/\d{4}/.test(key);

			const item = {
				value: element[key],
				style: {},
			};
			if (isDate && element[key] !== null) {
				const splitRowData = element[key].split('|');

				item.value = splitRowData[0];
				const endsWith = splitRowData[1].replace(/ /g, '');
				if (endsWith === 'O') {
					item.value = 'N/D';
				}
				if (endsWith === 'N' && item.value === 'Sin Turno ') {
					item.value = 'Sin horario';
				}
				switch (endsWith) {
					case 'H':
						item.style = {
							fill: '9bc8f2',
							border: 'thin',
							borderColor: '003A70',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case 'W':
						item.style = {
							fill: '9bc99b',
							border: 'thin',
							borderColor: '355F35',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case 'U':
						item.style = {
							fill: 'FFDDDD',
							border: 'thin',
							borderColor: '801212',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					default:
						item.style = {};
						break;
				}
			} else if (isDate && element[key] == null) {
				item.value = 'N/D';
			} else {
				switch (key) {
					case 'TotalHome':
						item.style = {
							fill: '9bc8f2',
							border: 'thin',
							borderColor: '003A70',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case 'TotalWork':
						item.style = {
							fill: '9bc99b',
							border: 'thin',
							borderColor: '355F35',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case 'TotalUnknown':
						item.style = {
							fill: 'FFDDDD',
							border: 'thin',
							borderColor: '801212',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					default:
						item.style = {};
						break;
				}
			}

			obj[key] = item;
		});
		result.push(obj);
	});
	return result;
};
const dataIncidencesToExport = (tableDataQuery) => {
	const result = [];
	tableDataQuery.forEach((element) => {
		const obj = {};
		Object.keys(element).forEach((key) => {
			const isDate = /\d{2}\/\d{2}\/\d{4}/.test(key);
			const item = {
				value: element[key],
				style: {},
			};
			if (isDate && element[key] !== null) {
				const splitRowData = element[key].split('|');
				item.value = splitRowData[0].replace(/ /g, '');
				const horas = Math.floor(item.value / 60);
				const minutos = item.value % 60;
				// Formatea las horas y los minutos a dos dígitos y conviértelos a string
				const horasFormateadas = horas < 10 ? '0' + horas : horas.toString();
				const minutosFormateados =
					minutos < 10 ? '0' + minutos : minutos.toString();
				item.value = horasFormateadas + ':' + minutosFormateados;
				const endsWith = splitRowData[1].replace(/ /g, '');
				if (endsWith === '8') {
					item.value = 'N/A';
				}
				if (endsWith === '10') {
					item.value = 'Sin horario';
				}
				switch (endsWith) {
					case '1':
						item.style = {
							fill: 'DCF4E2',
							border: 'thin',
							borderColor: '65CD81',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case '2':
						item.style = {
							fill: 'E7F3FF',
							border: 'thin',
							borderColor: '005EB8',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case '3':
						item.style = {
							fill: 'FFDDDD',
							border: 'thin',
							borderColor: 'FF8585',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case '4':
						item.style = {
							fill: 'E8E8E8',
							border: 'thin',
							borderColor: '757575',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case '5':
						item.style = {
							fill: 'FFF8DD',
							border: 'thin',
							borderColor: 'BF8F00',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case '6':
						item.style = {
							fill: 'A6BAE2',
							border: 'thin',
							borderColor: '305496',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case '7':
						item.style = {
							fill: 'EDE2F6',
							border: 'thin',
							borderColor: '7030A0',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case '9':
						item.style = {
							fill: 'D4CAF2',
							border: 'thin',
							borderColor: '896BDC',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					default:
						item.style = {};
						break;
				}
			} else if (isDate && element[key] == null) {
				item.value = 'N/A';
			} else {
				if (key.substring(0, 4) === 'time') {
					const horas = Math.floor(item.value / 60);
					const minutos = item.value % 60;
					// Formatea las horas y los minutos a dos dígitos y conviértelos a string
					const horasFormateadas = horas < 10 ? '0' + horas : horas.toString();
					const minutosFormateados =
						minutos < 10 ? '0' + minutos : minutos.toString();
					item.value = horasFormateadas + ':' + minutosFormateados;
				}
				switch (key) {
					case 'quantityAttendance':
					case 'timeAttendance':
						item.style = {
							fill: 'DCF4E2',
							border: 'thin',
							borderColor: '65CD81',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case 'quantityDayOff':
					case 'timeDayOff':
						item.style = {
							fill: 'E7F3FF',
							border: 'thin',
							borderColor: '005EB8',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case 'quantityAbscence':
					case 'timeAbscence':
						item.style = {
							fill: 'FFDDDD',
							border: 'thin',
							borderColor: 'FF8585',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case 'quantityInhability':
					case 'timeInhability':
						item.style = {
							fill: 'E8E8E8',
							border: 'thin',
							borderColor: '757575',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case 'quantityVacation':
					case 'timeVacation':
						item.style = {
							fill: 'A6BAE2',
							border: 'thin',
							borderColor: '305496',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case 'quantityDelay':
					case 'timeDelay':
						item.style = {
							fill: 'FFF8DD',
							border: 'thin',
							borderColor: 'BF8F00',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case 'quantityPermission':
					case 'timePermission':
						item.style = {
							fill: 'EDE2F6',
							border: 'thin',
							borderColor: '7030A0',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					case 'quantityRestDay':
					case 'timeRestDay':
						item.style = {
							fill: 'D4CAF2',
							border: 'thin',
							borderColor: '896BDC',
							horizontalAlignment: 'center',
							verticalAlignment: 'bottom',
						};
						break;
					default:
						break;
				}
			}
			obj[key] = item;
		});
		result.push(obj);
	});
	return result;
};

export const columnChecks = (rowData) => {
	if (rowData !== undefined && rowData !== null) {
		const splitRowData = rowData.split('|');
		const type = splitRowData[1].replace(/ /g, '');
		const detail =
			splitRowData[0] === 'Sin Turno ' ? 'Sin horario' : splitRowData[0];

		let dataColor = '';
		switch (type) {
			case 'H':
				dataColor = 'bg-[#003A70]';
				break;
			case 'W':
				dataColor = 'bg-[#355F35]';
				break;
			case 'U':
				dataColor = 'bg-[#801212]';
				break;
			default:
				dataColor = 'none';
				break;
		}
		return (
			<>
				<div className={`flex h-1 w-1 rounded-full  ${dataColor}   `} />
				<label
					title={detail}
					className='text-small-font-size text-[12px] ml-[2px] truncate  flex '>
					{type === 'N' && type === 'O' ? 'No aplica' : detail}
				</label>
			</>
		);
	} else {
		return (
			<label
				title={'No se encontró información'}
				className='text-small-font-size text-[14px] flex text-center '>
				{'N/D'}
			</label>
		);
	}
};

export const columnChecksIncidents = (rowData, field) => {
	if (rowData !== undefined && rowData !== null) {
		const splitRowData = rowData.split('|');
		const type = splitRowData[1].replace(/ /g, '');
		const dataColor = bgColors[type] || 'bg-[#498749]';
		const timing = parseInt(splitRowData[0]);
		let horas = Math.floor(timing / 60);
		let minutos = timing % 60 === 0 ? '0' : timing % 60;
		horas = horas < 10 ? '0' + horas : horas;
		minutos = minutos < 10 ? '0' + minutos : minutos;
		return type.toString() !== '8' && type.toString() !== '10' ? (
			<>
				<div className={`flex h-1 w-1 rounded-full  ${dataColor}   `} />
				<label
					title={horas + ':' + minutos}
					className='text-small-font-size text-[12px] ml-[2px] truncate  flex '>
					{horas}:{minutos}
				</label>
			</>
		) : (
			<label
				title={'No Aplica'}
				className='text-small-font-size text-[14px] flex text-center text-black'>
				{type.toString() === '10' ? 'Sin horario' : 'N/A'}
			</label>
		);
	} else {
		return (
			<label
				title={'No se encontró información'}
				className='text-small-font-size text-[14px] flex text-center text-black'>
				{'N/D'}
			</label>
		);
	}
};

export const columnData = (rowData) => {
	return (
		<label
			title={rowData}
			className='text-small-font-size text-[14px]  flex truncate '>
			{rowData}
		</label>
	);
};

export const homeOfficeBodyTemplate = (rowData) => {
	return (
		<span
			className={`bg-[#003A70] rounded-full h-3 w-8 text-center text-[14px] text-white justify-center items-center content-center flex`}>
			{rowData}
		</span>
	);
};
export const workCenterBodyTemplate = (rowData) => {
	return (
		<span
			className={`bg-[#355F35] rounded-full h-3 w-8 text-center text-[14px] text-white justify-center items-center content-center flex`}>
			{rowData}
		</span>
	);
};
export const unknownBodyTemplate = (rowData) => {
	return (
		<span
			className={`bg-[#801212] rounded-full h-3 w-8 text-center text-[14px] text-white justify-center items-center content-center flex`}>
			{rowData}
		</span>
	);
};

const bgColors = {
	timeAttendance: 'bg-[#65CD81]',
	quantityAttendance: 'bg-[#65CD81]',
	1: 'bg-[#65CD81]',
	timeDayOff: 'bg-[#CCD8E2]',
	quantityDayOff: 'bg-[#CCD8E2]',
	2: 'bg-[#CCD8E2]',
	timeAbscence: 'bg-[#FF8585]',
	quantityAbscence: 'bg-[#FF8585]',
	3: 'bg-[#FF8585]',
	timeInhability: 'bg-[#757575]',
	quantityInhability: 'bg-[#757575]',
	4: 'bg-[#757575]',
	timeDelay: 'bg-[#FFE485]',
	quantityDelay: 'bg-[#FFE485]',
	5: 'bg-[#FFE485]',
	timeVacation: 'bg-[#003A70]',
	quantityVacation: 'bg-[#003A70]',
	6: 'bg-[#003A70]',
	timePermission: 'bg-[#C9D2FF]',
	quantityPermission: 'bg-[#C9D2FF]',
	7: 'bg-[#C9D2FF]',
	timeRestDay: 'bg-[#896BDC]',
	quantityRestDay: 'bg-[#896BDC]',
	9: 'bg-[#896BDC]',
};

const textColors = {
	timeVacation: 'text-white',
	quantityVacation: 'text-white',
};

const bodyRowIncidencesTemplate = (rowData, field) => {
	if (rowData !== undefined && rowData !== null) {
		const dataColor = bgColors[field] || 'bg-[#498749]';
		const textColor = textColors[field] || 'text-black';
		// Validar si los primeros 4 caracteres de field son "time"
		const isTimeField = field.substring(0, 4) === 'time';
		let horas = Math.floor(rowData / 60);
		let minutos = rowData % 60 === 0 ? '0' : rowData % 60;
		horas = horas < 10 ? '0' + horas : horas;
		minutos = minutos < 10 ? '0' + minutos : minutos;
		return (
			<span
				className={`${dataColor} ${textColor}  rounded-full h-3 w-6 text-center text-[14px] justify-center items-center content-center flex`}>
				{isTimeField ? (
					<span>
						{horas}:{minutos}
					</span>
				) : (
					rowData
				)}
			</span>
		);
	} else {
		return (
			<label
				title={'No se encontró información'}
				className='text-small-font-size text-[14px] flex text-center'>
				{'N/D'}
			</label>
		);
	}
};

function getDaysBetweenDates(startDate, endDate) {
	const days = [];
	let currentDate = new Date(startDate);

	while (currentDate <= endDate) {
		const newDate = new Date(currentDate);
		const formattedDate = formatDate(newDate);

		days.push(formattedDate);
		currentDate.setDate(currentDate.getDate() + 1);
	}

	return days;
}
const initArrayColumns = [
	{
		field: 'Code',
		header: 'Código',
		sortable: true,
		frozen: true,
		alignFrozen: 'left',
		width: '60px',
		body: columnData,
	},
	{
		field: 'CollaboratorName',
		header: 'Nombre',
		sortable: true,
		frozen: true,
		alignFrozen: 'left',
		width: '100px',
		body: columnData,
	},
	{
		field: 'CollaboratorLead',
		header: 'Líder',
		sortable: true,
		frozen: true,
		alignFrozen: 'left',
		width: '100px',
		body: columnData,
	},
];
const endArrayColumns = [
	{
		field: 'TotalHome',
		header: 'Lugar de trabajo',
		sortable: true,
		//frozen: true,
		alignFrozen: 'right',
		width: '200px',
		body: homeOfficeBodyTemplate,
	},
	{
		field: 'TotalWork',
		header: 'Centro de trabajo',
		sortable: true,
		//frozen: true,
		alignFrozen: 'right',
		width: '200px',
		body: workCenterBodyTemplate,
	},
	{
		field: 'TotalUnknown',
		header: 'Otra ubicación',
		sortable: true,
		//frozen: true,
		alignFrozen: 'right',
		width: '200px',
		body: unknownBodyTemplate,
	},
];

export function generateDynamicColumns(
	showColumns,
	startDate,
	endDate,
	typeQuery,
	totalizer,
	incidentTypeSelect
) {
	const daysBetweenDates = getDaysBetweenDates(startDate, endDate);

	const arregloDeFechas = daysBetweenDates.map((fecha, index) => ({
		field: fecha,
		header: fecha,
		sortable: false,
		frozen: false,
		width: '120px',
		body: typeQuery.code === 'C' ? columnChecks : columnChecksIncidents,
	}));

	const endArrayColumnsIncidences = [
		{
			field: totalizer ? 'timeAttendance' : 'quantityAttendance',
			header: 'Asistencias',
			sortable: true,
			//frozen: true,
			alignFrozen: 'right',
			width: '200px',
			body: bodyRowIncidencesTemplate,
			id: 1,
		},
		{
			field: totalizer ? 'timeDayOff' : 'quantityDayOff',
			header: 'No laborados',
			sortable: true,
			//frozen: true,
			alignFrozen: 'right',
			width: '200px',
			body: bodyRowIncidencesTemplate,
			id: 2,
		},
		{
			field: totalizer ? 'timeRestDay' : 'quantityRestDay',
			header: 'Día de descanso',
			sortable: true,
			//frozen: true,
			alignFrozen: 'right',
			width: '200px',
			body: bodyRowIncidencesTemplate,
			id: 8,
		},
		{
			field: totalizer ? 'timeAbscence' : 'quantityAbscence',
			header: 'Faltas',
			sortable: true,
			//frozen: true,
			alignFrozen: 'right',
			width: '200px',
			body: bodyRowIncidencesTemplate,
			id: 3,
		},
		{
			field: totalizer ? 'timeInhability' : 'quantityInhability',
			header: 'Incapacidad',
			sortable: true,
			//frozen: true,
			alignFrozen: 'right',
			width: '200px',
			body: bodyRowIncidencesTemplate,
			id: 4,
		},
		{
			field: totalizer ? 'timeDelay' : 'quantityDelay',
			header: 'Retardos',
			sortable: true,
			//frozen: true,
			alignFrozen: 'right',
			width: '200px',
			body: bodyRowIncidencesTemplate,
			id: 5,
		},
		{
			field: totalizer ? 'timeVacation' : 'quantityVacation',
			header: 'Vacaciones',
			sortable: true,
			//frozen: true,
			alignFrozen: 'right',
			width: '200px',
			body: bodyRowIncidencesTemplate,
			id: 6,
		},

		{
			field: totalizer ? 'timePermission' : 'quantityPermission',
			header: 'Permiso',
			sortable: true,
			//frozen: true,
			alignFrozen: 'right',
			width: '200px',
			body: bodyRowIncidencesTemplate,
			id: 7,
		},
	];

	let dataValidationType = [];
	if (typeQuery.code === 'I') {
		dataValidationType = endArrayColumnsIncidences;
	} else {
		dataValidationType = endArrayColumns;
	}

	const arrayTwoDataColumns = [...initArrayColumns, ...dataValidationType];
	const endColumns =
		typeQuery.code === 'C' ? endArrayColumns : dataValidationType;

	const arrayDataColumns = [
		...initArrayColumns,
		...arregloDeFechas,
		...endColumns,
	];

	return { open: arrayDataColumns, close: arrayTwoDataColumns };
}
