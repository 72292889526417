import { useState, useContext, useEffect } from 'react';
import {
	GET_INVITATIONS,
	GET_INVITATIONS_SUCCESS,
	GET_INVITATIONS_ERROR,
	SEND_INVITATIONS,
	SEND_INVITATIONS_SUCCESS,
	SEND_INVITATIONS_ERROR,
	RE_IMPORT_DATA,
	RE_IMPORT_DATA_SUCCESS,
	RE_IMPORT_DATA_ERROR,
	VERIFY_AVAILABLE_LICENSE,
	VERIFY_AVAILABLE_LICENSE_SUCCESS,
	VERIFY_AVAILABLE_LICENSE_ERROR,
	SEND_UN_INVITE,
	SEND_UN_INVITE_SUCCESS,
	SEND_UN_INVITE_ERROR,
	INVITATION_FORWARD,
	INVITATION_FORWARD_SUCCESS,
	INVITATION_FORWARD_ERROR,
	LOADING_ON,
	LOADING_OFF,
} from '../../business/constants';
import { StoreContext } from '../../business/Provider';
import { Toast } from '../../components/toast';
import useApi from '../api';
import { generateUrlBase } from '../../utils/utils';
import { AlertToastInfoNames } from '../../namesConstants/names';

const useInvitations = (
	updateProgressBar,
	setShowProgressBarDialog,
	validateErrorsInData
) => {
	//console.log('useInvitations', updateProgressBar);
	const [invitations, setInvitations] = useState(undefined);
	const { genericApiCall } = useApi();
	const {
		getInvitationsState,
		dispatchGetInvitations,
		dispatchPostInvitations,
		dispatchSendUnInvite,
		dispatchInvitationForward,
		dispatchLoading,
		dispatchReImportData,
		dispatchVerifyAvailableLicense,
	} = useContext(StoreContext);

	// Helper functions to handle common actions
	const handleLoading = (isLoading) => {
		dispatchLoading({ type: isLoading ? LOADING_ON : LOADING_OFF });
	};

	const handleToast = (type, message, details = '') => {
		Toast(type, message, '', details);
	};

	// Consolidated API call with basic structure
	const apiCall = async (
		url,
		method,
		data = {},
		dispatchSuccess,
		dispatchError
	) => {
		handleLoading(true);
		try {
			const result = await genericApiCall(url, method, data);
			if (result.status === 200) {
				dispatchSuccess(result.data);
			} else {
				dispatchError(result.message);
			}
		} catch (error) {
			dispatchError(error.message);
			handleToast('warning', error.message);
		} finally {
			handleLoading(false);
		}
	};

	// Fetch Invitations
	const getInvitations = async () => {
		dispatchGetInvitations({ type: GET_INVITATIONS });
		const url = generateUrlBase('collaborators');

		await apiCall(
			url,
			'GET',
			{},
			(data) => {
				const formattedData = data.results.map((item) => ({
					...item,
					invitationResponseDate: item.invitationResponseDate
						? new Date(item.invitationResponseDate)
						: null,
					invitationSendingDate: item.invitationSendingDate
						? new Date(item.invitationSendingDate)
						: null,
				}));
				dispatchGetInvitations({
					type: GET_INVITATIONS_SUCCESS,
					payload: formattedData,
				});
			},
			(message) => {
				dispatchGetInvitations({
					type: GET_INVITATIONS_ERROR,
					payload: {
						header: 'Error',
						body: message || 'Error fetching invitations',
					},
				});
			}
		);
	};

	//Send Invitations
	const sendInvitation = async (data) => {
		dispatchPostInvitations({ type: SEND_INVITATIONS });
		const url = generateUrlBase('invitations/invite');

		await apiCall(
			url,
			'POST',
			data,
			() => {
				dispatchPostInvitations({
					type: SEND_INVITATIONS_SUCCESS,
					payload: data,
				});
				handleToast('success', AlertToastInfoNames.SendInvitationSuccess);
				getInvitations(); // Refetch invitations
			},
			(message) => {
				dispatchPostInvitations({
					type: SEND_INVITATIONS_ERROR,
					payload: { header: 'Error', body: message },
				});
			}
		);
	};

	function splitArray(arr, n) {
		const result = [];

		for (let i = 0; i < arr.length; i += n) {
			result.push(arr.slice(i, i + n));
		}

		return result;
	}
	const sendInvitationsInParallel = async (dataArray) => {
		dispatchPostInvitations({ type: SEND_INVITATIONS });

		const totalValue =
			window.REACT_APP_BATCH_INVITATIONS !== undefined &&
			window.REACT_APP_BATCH_INVITATIONS !== null
				? parseInt(window.REACT_APP_BATCH_INVITATIONS, 10)
				: 20; // Leer el valor de la variable de entorno
		const dataSplit = splitArray(dataArray, totalValue); // Divide el array en partes
		const urls = dataSplit.map(() => generateUrlBase('invitations/invite'));

		const totalRequests = urls.length;
		let completedRequests = 0;
		let newData = [];
		try {
			const promises = urls.map((url, index) =>
				apiCall(
					url,
					'POST',
					dataSplit[index], // Pasamos el chunk de datos correspondiente
					(data) => {
						completedRequests++;
						newData.push(...data.data.errorInvitations);
						updateProgressBar((completedRequests / totalRequests) * 100); // Actualizamos el progreso
						dispatchPostInvitations({
							type: SEND_INVITATIONS_SUCCESS,
							payload: dataSplit[index], // El chunk que fue procesado
						});
					},
					(message) => {
						dispatchPostInvitations({
							type: SEND_INVITATIONS_ERROR,
							payload: { header: 'Error', body: message },
						});
					}
				)
			);

			await Promise.all(promises); // Esperamos a que todas las promesas se resuelvan
			//console.log('Todos los datos obtenidos:', results);

			handleToast('success', AlertToastInfoNames.SendInvitationSuccess); // Mostramos toast de éxito
			setShowProgressBarDialog(false); // Ocultamos el diálogo de progreso
			//console.log('new Data to send', newData);
			validateErrorsInData(newData);
			getInvitations(); // Refetch invitations once all are completed
		} catch (error) {
			console.error('Error en las peticiones:', error);
			handleToast('warning', error.message); // Mostramos toast de error
		}
	};

	//sen invitation massive

	// Send Invitation Forward
	const sendInvitationForward = async (data) => {
		dispatchInvitationForward({ type: INVITATION_FORWARD });
		const url = generateUrlBase('invitations/forward');

		await apiCall(
			url,
			'POST',
			data,
			() => {
				dispatchInvitationForward({
					type: INVITATION_FORWARD_SUCCESS,
					payload: data,
				});
				handleToast('success', AlertToastInfoNames.SendInvitationSuccess);
				getInvitations(); // Refetch invitations
			},
			(message) => {
				dispatchInvitationForward({
					type: INVITATION_FORWARD_ERROR,
					payload: { header: 'Error', body: message },
				});
			}
		);
	};

	// Send Un-invite
	const sendUnInvite = async (data) => {
		dispatchSendUnInvite({ type: SEND_UN_INVITE });
		const url = generateUrlBase('invitations/invite');

		await apiCall(
			url,
			'DELETE',
			data,
			(result) => {
				dispatchSendUnInvite({
					type: SEND_UN_INVITE_SUCCESS,
					payload: result,
				});
				handleToast('success', AlertToastInfoNames.sendUnInvitationSuccess);
				getInvitations(); // Refetch invitations
			},
			(message) => {
				dispatchSendUnInvite({
					type: SEND_UN_INVITE_ERROR,
					payload: { header: 'Error', body: message },
				});
			}
		);
	};

	const sendUnInviteParallel = async (data) => {};

	// Re-Import Data
	const reImportDataUser = async (data) => {
		dispatchReImportData({ type: RE_IMPORT_DATA });
		const url = generateUrlBase('import-data');

		await apiCall(
			url,
			'POST',
			data,
			(result) => {
				dispatchReImportData({
					type: RE_IMPORT_DATA_SUCCESS,
					payload: result,
				});
				handleToast('success', AlertToastInfoNames.ReImportDataSuccess);
			},
			() => {
				dispatchReImportData({ type: RE_IMPORT_DATA_ERROR });
			}
		);
	};

	// Verify Available License
	const verifyAvailableLicense = async (data) => {
		dispatchVerifyAvailableLicense({ type: VERIFY_AVAILABLE_LICENSE });
		const url = generateUrlBase('invitations/verify-available');

		await apiCall(
			url,
			'POST',
			data,
			(result) => {
				if (result < data.length) {
					dispatchVerifyAvailableLicense({
						type: VERIFY_AVAILABLE_LICENSE_ERROR,
					});
					handleToast('warning', AlertToastInfoNames.MaxLicenseAvailable);
				} else {
					dispatchVerifyAvailableLicense({
						type: VERIFY_AVAILABLE_LICENSE_SUCCESS,
						payload: result,
					});
				}
			},
			() => {
				dispatchVerifyAvailableLicense({
					type: VERIFY_AVAILABLE_LICENSE_ERROR,
				});
			}
		);
	};

	useEffect(() => {
		if (!getInvitationsState.isDataLoaded) getInvitations();
	}, []);

	return {
		invitations,
		setInvitations,
		getInvitations,
		sendInvitation,
		sendInvitationsInParallel,
		sendInvitationForward,
		sendUnInvite,
		reImportDataUser,
		verifyAvailableLicense,
		sendUnInviteParallel,
	};
};

export default useInvitations;
