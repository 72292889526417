import {
	PUT_ORG_CHART_LEADERS,
	PUT_ORG_CHART_LEADERS_ERROR,
	PUT_ORG_CHART_LEADERS_SUCCESS,
} from '../../constants';

export const putLeadersOrganizationChartReducer = (state, actions) => {
	switch (actions.type) {
		case PUT_ORG_CHART_LEADERS:
			return {
				...state,
				loading: true,
				error: null,
				errorShow: false,
			};
		case PUT_ORG_CHART_LEADERS_ERROR:
			return {
				...state,
				loading: false,
				error: actions.payload,
				errorShow: true,
			};
		case PUT_ORG_CHART_LEADERS_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				errorShow: false,
				data: actions.payload,
			};
		default:
			return state;
	}
};
