import React from 'react';

const Spinner = () => (
	<div className='flex justify-center content-center items-center h-screen w-full flex-col'>
		<div className='w-16 h-16 border-4 border-t-4 border-blue-500 border-solid rounded-full animate-spin'></div>
		<span>Cargando Pagina...</span>
	</div>
);

export default Spinner;
