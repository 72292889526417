import { useReducer } from 'react';
import { getInvitationsReducer } from '../../../business/reducers/invitations/GetInvitationsReducer';
import { getInvitationsInitialState } from '../../../business/initialState/invitations/getInvitationsInitialState';

export const useGetInvitationsState = () => {
	const [state, dispatch] = useReducer(
		getInvitationsReducer,
		getInvitationsInitialState
	);
	return { state, dispatch };
};
